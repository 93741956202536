/**
 * @ngdoc service
 * @name buildInfoModule
 * @description This is a Transform module used to transform JSON object to
 *              respective Model object This module defines the BuildInfoRestService
 *              which will get the build details from the server, This service
 *              reads the property file from the server which contains the build
 *              information.
 *              BuildInfoService defines the methods to construct Model data
 *              and retrieve the model data.
 */
(function () {
  'use strict';
  var app = angular.module('buildInfoModule', []);
  //This factory defines the methods to invoke http operations
  app.factory('BuildInfoRestService', [
    '$http',
    function ($http) {
      return {
        getBuildInfo: function () {
          var time = new Date().getTime();
          var path = '/buildinfo.json' + '?time=' + time;
          return $http.get(path);
        },
      };
    },
  ]);

  //This service defines methods to construct and read Model data
  app.service('BuildInfoService', function () {
    this.createBuildInfo = function (jsonData) {
      return {
        time: jsonData.time,
        version: jsonData.version,
      };
    };
  });
})();
