import 'Modules/user/user.module';
import csdService from '@/shared/services/csd.service';
import issuerPreferencesService from '@/shared/services/issuerPreferences.service';
import entitlementsService from '@/shared/services/entitlements.service';
import { yyyyMmDdToDdMmYyyy } from '@/lib/date';

('use strict');
const common = angular.module('commonModule', [
  'ui.router',
  'ngStorage',
  'ngRoute',
  'ngResource',
  'ngIdle',
  'user',
  'buildInfoModule',
]);

function dataToDataUrl(data, type) {
  return `data:${type};base64,${btoa(data)}`;
}

common.factory('cbpDownload', function () {
  return function (content, filename, { type = 'text/plain' } = {}) {
    if (typeof navigator.msSaveBlob === 'function') {
      const blob = new Blob([content]);
      navigator.msSaveBlob(blob, filename);
    } else {
      const a = document.createElement('a');
      a.href = dataToDataUrl(content, type);
      a.setAttribute('download', filename);
      a.click();
    }
  };
});

common.factory('cbpDownloadBlob', function () {
  return function (blob, filename) {
    if (typeof navigator.msSaveBlob === 'function') {
      navigator.msSaveBlob(blob, filename);
    } else {
      const a = document.createElement('a');
      a.href = URL.createObjectURL(blob);
      a.setAttribute('download', filename);
      a.click();
    }
  };
});

common.factory('csdService', csdService);
common.factory('issuerPreferencesService', issuerPreferencesService);
common.factory('entitlementsService', entitlementsService);

common.filter('yyyyMmDdToDdMmYyyy', () => yyyyMmDdToDdMmYyyy);
